$large-font: 3rem;

%fill-and-center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

%normal-font {
  font-family: -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

%monospace-font {
  font-family: "Lucida Console",
    Monaco,
    monospace;
}

.App {
  text-align: center;
}

#main {
  @extend %fill-and-center;
  background-color: #282c34;
  color: #eeeeee;
}

#name {
  font-size: $large-font;
}

.i-am {
  @extend %monospace-font;
  display: inline;
  white-space: pre-wrap;
}

.under-construction-pic {
  width: 60%;
  pointer-events: none;
}